.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}

@keyframes fadeIn{
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}